import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { branch, compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "theme/components/atoms/Button";
import Button from "theme/components/atoms/Button";
import { FormattedMessage } from "react-intl";
import Icon from "theme/components/atoms/Icon";
import branchServerClient from "web/core/branchServerClient";

const MAX_DISPLAY_COUNT = 2;

async function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

function getDisplayCount(key) {
  return parseInt(localStorage.getItem(key) || "0", 10);
}

function incrementDisplayCount(key) {
  const currentCount = getDisplayCount(key);
  localStorage.setItem(key, currentCount + 1);
}

const NewVisitorModal = ({ newVisitorPopup }) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  useEffect(() => {
    const displayCountKey = `newVisitorPopupDisplayCount_${newVisitorPopup.id}`;
    const displayCount = getDisplayCount(displayCountKey);

    if (newVisitorPopup.canDisplay && displayCount < MAX_DISPLAY_COUNT) {
      setTimeout(() => {
        setDisplayPopup(true);
        incrementDisplayCount(displayCountKey);
      }, newVisitorPopup.displayAfter * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (displaySuccess) {
      setTimeout(() => {
        setDisplaySuccess(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySuccess]);

  if (!displayPopup) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        setDisplayPopup(false);
      }}
      overlayClassName="modal__overlay product-added__overlay"
      contentLabel="Product Stock Window"
      className="modal__content product-added modal-alert-stock modal-new-visitor"
    >
      <div className="product-added_background">
        <IconButton
          icon="cross"
          size="small"
          appearance="icon"
          title="close"
          onClick={() => {
            setDisplayPopup(false);
          }}
        />
        <section className="product-added__wrapper">
          <div className="product-added__title">
            {newVisitorPopup.content.title}
          </div>
          <small>{newVisitorPopup.content.description}</small>
          {newVisitorPopup.content.promoCode &&
            newVisitorPopup.content.copyButtonLabel && (
              <div className="modal-new-visitor__actions">
                <Button
                  onClick={() => {
                    copyToClipboard(newVisitorPopup.content.promoCode);
                    setDisplaySuccess(true);
                  }}
                >
                  {newVisitorPopup.content.promoCode}
                </Button>

                <Button
                  appearance="primary"
                  onClick={() => {
                    copyToClipboard(newVisitorPopup.content.promoCode);
                    setDisplaySuccess(true);
                  }}
                >
                  {displaySuccess ? (
                    <>
                      <Icon icon="ok" title="success" />
                      <FormattedMessage
                        id="components.organisms.NewVisitorModal.copySuccess"
                        defaultMessage="Copy successfully"
                      />
                    </>
                  ) : (
                    newVisitorPopup.content.copyButtonLabel
                  )}
                </Button>
              </div>
            )}
        </section>
      </div>
    </Modal>
  );
};

NewVisitorModal.propTypes = {
  newVisitorPopup: PropTypes.object,
};

const NewVisitorModals = (props) => {
  return props.newVisitorPopup
    ?.filter((elem) => elem.canDisplay)
    ?.filter((elem) => {
      if (
        window?.location?.pathname === "/" &&
        elem.urlFindToDisplay === "home"
      ) {
        return true;
      }

      if (
        typeof window !== "undefined" &&
        window?.location?.href?.search(elem.urlFindToDisplay) === -1
      ) {
        return false;
      }
      return true;
    })
    ?.map((popupContent) => (
      <NewVisitorModal newVisitorPopup={popupContent} key={popupContent.id} />
    ));
};

NewVisitorModals.propTypes = {
  newVisitorPopup: PropTypes.array,
};

export default compose(
  branchServerClient(
    () => () => null,
    (BaseComponent) => BaseComponent
  ),
  branch(
    (props) => !props.newVisitorPopup?.length,
    () => () => null,
    (BaseComponent) => BaseComponent
  )
)(NewVisitorModals);
